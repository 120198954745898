//
//  type.js

const type = (function () {
  const paragraphs = Array.from(document.getElementsByTagName('p'));

  //  Private
  function preventOrphans() {
    //  1. In every paragraph
    paragraphs.forEach((para) => {
      //  2. Get the contents
      const contents = para.innerHTML;

      //  3. Split every character into an array
      const split = contents.split('');

      //  4. Find the position of the last space
      const lastSpaceIndex = split.lastIndexOf(' ');

      //  5. If it exists
      if (lastSpaceIndex) {
        //  6. Replace it with a non-breaking space
        split[lastSpaceIndex] = '&nbsp;';

        //  7. Join the array together and set it as the content
        para.innerHTML = split.join('');
      }
    });
  }

  //  Public: initialise
  function init() {
    if (paragraphs.length) {
      preventOrphans();
    }
  }

  //  Public API
  return {
    init,
  };
})(); // IIFE

/*
 *  Exports
 */
export { type as default };
