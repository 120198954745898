//
//  carousel.js

const carousel = (function () {
  let allCarousels;
  const activeClass = 'is-active';
  const inTransitionClass = 'is-inTransition';

  //  Private
  function isInViewport(element, percentage = '0%') {
    const html = document.documentElement;
    const height = window.innerHeight || html.clientHeight;
    const rect = element.getBoundingClientRect();
    //  allowance gives a percentage of leeway on all sides
    //  20% becomes an allowance of 0.2
    const allowance = parseInt(percentage, 10) / 100;

    //  Top
    if (!(rect.top >= 0 - height * allowance)) return false;

    //  Bottom
    if (!(rect.bottom <= height + height * allowance)) return false;

    //  If all check passed, return true
    return true;
  }

  //  Private
  function transitionCarousel(carouselObj) {
    const carouselEl = carouselObj.el;

    //  Go in to transition
    carouselEl.classList.add(inTransitionClass);
    carouselObj.isInTransition = true;

    //  Allow 1 second transition
    setTimeout(() => {
      carouselEl.classList.remove(inTransitionClass);
      carouselObj.isInTransition = false;
    }, 1250);
  }

  //  Private
  function toggleDisabledOnButtons(carouselObj, newIndex) {
    const totalSlides = carouselObj.slides.length;

    //  Prev
    const prevButton = carouselObj.prevButton;
    if (newIndex > 0) {
      prevButton.removeAttribute('disabled');
    } else {
      prevButton.setAttribute('disabled', '');
    }

    //  Next
    const nextButton = carouselObj.nextButton;
    if (newIndex < totalSlides - 1) {
      nextButton.removeAttribute('disabled');
    } else {
      nextButton.setAttribute('disabled', '');
    }
  }

  //  Private
  function goPrev(e, carouselObj) {
    e.preventDefault();

    const index = carouselObj.index;
    //  Only continue if there is a previous slide to view
    if (index <= 0) return;

    //  Only continue if the carousel isn’t already in transition
    if (carouselObj.isInTransition) return;

    //  Put the carousel in transition
    transitionCarousel(carouselObj);

    const interval = setInterval(() => {
      //  Only continue if carousel is out of transition
      if (carouselObj.isInTransition) return;

      //  Make sure no slides are active
      const slides = carouselObj.slides;
      slides.forEach((slide) => {
        slide.classList.remove(activeClass);
      });

      //  Set the previous slide as active
      slides[index - 1].classList.add(activeClass);

      //  Update the index
      carouselObj.index -= 1;

      //  Toggle disabled attribute on buttons based on new index
      toggleDisabledOnButtons(carouselObj, index - 1);

      //  Clear the interval
      clearInterval(interval);
    }, 10);
  }

  function goNext(e, carouselObj) {
    e.preventDefault();

    const index = carouselObj.index;
    const totalSlides = carouselObj.slides.length;
    //  Only continue if there is a next slide to view
    //  -1 to zero-index, -2 for the next slide to come
    if (index > totalSlides - 2) return;

    //  Only continue if the carousel isn’t already in transition
    if (carouselObj.isInTransition) return;

    //  Put the carousel in transition
    transitionCarousel(carouselObj);

    const interval = setInterval(() => {
      //  Only continue if carousel is out of transition
      if (carouselObj.isInTransition) return;

      //  Make sure no slides are active
      const slides = carouselObj.slides;
      slides.forEach((slide) => {
        slide.classList.remove(activeClass);
      });

      //  Set the next slide as active
      slides[index + 1].classList.add(activeClass);

      //  Update the index
      carouselObj.index += 1;

      //  Toggle disabled attribute on buttons based on new index
      toggleDisabledOnButtons(carouselObj, index + 1);

      //  Clear the interval
      clearInterval(interval);
    }, 10);
  }

  //  Private
  function keyNav(carouselObj) {
    window.addEventListener('keydown', (e) => {
      if (e.keyCode === 37 && isInViewport(carouselObj.el, '20%')) {
        goPrev(e, carouselObj);
      } else if (e.keyCode === 39 && isInViewport(carouselObj.el, '20%')) {
        goNext(e, carouselObj);
      }
    });
  }

  //  Private
  function setUpCarousels() {
    //  Loop through each carousel
    allCarousels.forEach((el) => {
      const prevButton = el.querySelector('.js-Carousel-button--prev');
      const nextButton = el.querySelector('.js-Carousel-button--next');
      const slides = Array.from(el.querySelectorAll('.js-Slide'));

      const carouselObj = {
        el,
        prevButton,
        nextButton,
        slides,
        index: 0,
        isInTransition: false,
      };

      //  Listen for button clicks
      if (prevButton) {
        prevButton.addEventListener('click', (e) => {
          goPrev(e, carouselObj);
        });
      }

      if (nextButton) {
        nextButton.addEventListener('click', (e) => {
          goNext(e, carouselObj);
        });
      }

      //  Listen for keyNav
      keyNav(carouselObj);
    });
  }

  //  Public: initialise
  function init() {
    allCarousels = Array.from(document.querySelectorAll('.js-Carousel'));
    if (allCarousels.length) {
      setUpCarousels();
    }
  }

  //  Public API
  return {
    init,
  };
})(); //  IIFE

/*
 *  Exports
 */
export { carousel as default };
