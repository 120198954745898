//
//  main.js

//  LazySizes
import 'lazysizes';

//  Components
import carousel from './carousel';

//  Misc
import typography from './typography';

//  Initiate
carousel.init();
typography.init();
